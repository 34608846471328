<div class="flex h-5 items-center">
  <input
    type="checkbox"
    [id]="id"
    [checked]="value"
    (change)="updateValue()"
    [disabled]="disabled"
    class="focus:ring-primary text-primary h-4 w-4 rounded border-gray-300"
  />
</div>
