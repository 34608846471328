/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-error-plain',
  templateUrl: './control-error.component.html',
  styles: [],
  host: {
    class: 'block mt-1 text-sm text-red-600',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlErrorPlainComponent {}
