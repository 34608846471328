<div>
  <label
    [for]="id"
    class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
  >
    {{ label }}
  </label>
</div>
<div class="sm:col-span-2">
  <mspot-form-field-base
    [invalid]="control?.touched && control.invalid"
    [errors]="control.errors"
  >
    <ng-content></ng-content>
    <span suffix>
      <ng-content select="[mspotInputSuffix]"></ng-content>
    </span>
  </mspot-form-field-base>
</div>
