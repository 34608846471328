import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'formStatus',
  pure: true,
})
export class FormStatusPipe implements PipeTransform {
  transform(status: UntypedFormGroup['status'], ...args: unknown[]): boolean {
    // console.log(status);
    if (status === 'VALID') {
      return false;
    }

    return true;
  }
}
