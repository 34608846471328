import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { InputErrorDirective } from '../input-error/input-error.directive';

@UntilDestroy()
@Component({
  selector: 'mspot-form-section-field',
  templateUrl: './form-section-field.component.html',
  styles: [],
  host: {
    class:
      'block space-y-1 px-4 py-5 md:space-y-0 md:grid md:grid-cols-3 md:px-6',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionFieldComponent {
  id?: string;

  @Input() label?: string;
  @Input() subtitle?: string;

  @Input()
  @HostBinding('class')
  mdGap = 'md:gap-6';

  control?: NgControl;

  // @ContentChild(InputDirective) set input(val: InputDirective) {
  //   if (val) {
  //     this.id = val.host.id;
  //   }
  // }

  @ContentChild(InputErrorDirective) set inputError(val: InputErrorDirective) {
    if (val) {
      this.id = val.host.id;
      this.control = val.ngControl;
      val.registerCdr(this.cdr);
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
