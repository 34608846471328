import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FrontendSharedUiCheckboxModule } from '@memberspot/frontend/shared/ui/checkbox';
import { FrontendSharedUiRadioGroupModule } from '@memberspot/frontend/shared/ui/radio-group';
import { FrontendSharedUiSvgControlsModule } from '@memberspot/frontend/shared/ui/svg-controls';
import { FrontendSharedUiToggleModule } from '@memberspot/frontend/shared/ui/toggle';
import { FrontendSharedUiTooltipModule } from '@memberspot/frontend/shared/ui/tooltip';
import { FrontendSharedUtilFormModule } from '@memberspot/frontend/shared/util/form';
import { NgIconsModule } from '@ng-icons/core';
import { TranslocoModule } from '@ngneat/transloco';
import { RxLet } from '@rx-angular/template/let';

import { ControlErrorComponent } from './control-error/control-error.component';
import { ControlErrorPlainComponent } from './control-error-plain/control-error.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { FormFieldLabelDirective } from './form-field-label/form-field-label.component';
import {
  FormFieldStdComponent,
  FormFieldStdTooltipComponent,
} from './form-field-std/form-field-std.component';
import { FormSectionFieldComponent } from './form-section-field/form-section-field.component';
import { FormSectionFieldSmComponent } from './form-section-field-sm/form-section-field-sm.component';
import { FormSectionsWrapperComponent } from './form-sections-wrapper/form-sections-wrapper.component';
import { InputEnableBtnComponent } from './input-enable-btn/input-enable-btn.component';
import { InputErrorMinWidthDirective } from './input-error/input-error-min-w.directive';
import { InputNoMinDirective } from './input-error/input-no-min.directive';
import { InputErrorNoBorderDirective } from './input-error/input-noborder.directive';
import { InputErrorNoBorderFocusDirective } from './input-error/input-noborder-focus.directive';
import { InputPrefixComponent } from './input-prefix/input-prefix.component';
import { InputPrefixDirective } from './input-prefix-dir/input-prefix.component';
import { InputSuffixBtnComponent } from './input-suffix-btn/input-suffix-btn.component';
import { SearchAddComponent } from './search-add/search-add.component';
import { IconPipe } from './select/icon.pipe';
import { OptionComponent } from './select/option/option.component';
import { OptionMultiComponent } from './select/option-multi/option.component';
import { SelectComponent } from './select/select-basic/select.component';

@NgModule({
  imports: [
    CommonModule,
    FrontendSharedUtilFormModule,
    NgIconsModule,
    OverlayModule,
    ScrollingModule,
    FrontendSharedUiToggleModule,
    FrontendSharedUiCheckboxModule,
    FrontendSharedUiRadioGroupModule,
    MatTooltipModule,
    ReactiveFormsModule,
    FrontendSharedUiTooltipModule,
    FrontendSharedUiSvgControlsModule,
    RouterModule,
    FrontendSharedUiToggleModule,
    TranslocoModule,
    RxLet,
  ],
  declarations: [
    FormSectionsWrapperComponent,
    FormSectionFieldComponent,
    InputErrorMinWidthDirective,
    InputNoMinDirective,
    InputErrorNoBorderDirective,
    ControlErrorComponent,
    SelectComponent,
    FormFieldComponent,
    FormSectionFieldSmComponent,
    FormFieldStdComponent,
    InputPrefixComponent,
    OptionComponent,
    IconPipe,
    FormFieldLabelDirective,
    OptionMultiComponent,
    InputEnableBtnComponent,
    InputSuffixBtnComponent,
    InputPrefixDirective,
    ControlErrorPlainComponent,
    FormFieldStdTooltipComponent,
    SearchAddComponent,
    InputErrorNoBorderFocusDirective,
  ],
  exports: [
    FormSectionsWrapperComponent,
    FormSectionFieldComponent,
    InputErrorMinWidthDirective,
    InputNoMinDirective,
    InputErrorNoBorderDirective,
    ControlErrorComponent,
    SelectComponent,
    FormSectionFieldSmComponent,
    FormFieldStdComponent,
    InputPrefixComponent,
    OptionComponent,
    FrontendSharedUiToggleModule,
    FormFieldLabelDirective,
    FrontendSharedUiCheckboxModule,
    FrontendSharedUiRadioGroupModule,
    InputEnableBtnComponent,
    InputSuffixBtnComponent,
    InputPrefixDirective,
    ControlErrorPlainComponent,
    ReactiveFormsModule,
    FormFieldStdTooltipComponent,
    FrontendSharedUiSvgControlsModule,
    SearchAddComponent,
    FrontendSharedUiToggleModule,
    InputErrorNoBorderFocusDirective,
  ],
})
export class FrontendSharedUiControlsModule {}
