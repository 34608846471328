import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';

import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'mspot-checkbox[noLabel]',
  templateUrl: './checkbox.component.html',
  styles: [],
  providers: [
    {
      provide: CheckboxComponent,
      useExisting: forwardRef(() => CheckboxNoLabelComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxNoLabelComponent extends CheckboxComponent {}
