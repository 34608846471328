/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { merge, Subscription } from 'rxjs';

import { RadioButtonComponent } from '../radio-button/radio-button.component';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class RadioGroupBaseComponent
  implements ControlValueAccessor, OnDestroy
{
  subscription?: Subscription;

  @Input() name = '';

  private _radioButtons?: QueryList<RadioButtonComponent>;
  @ContentChildren(RadioButtonComponent)
  set radioButtons(radioButtons: QueryList<RadioButtonComponent>) {
    this._radioButtons = radioButtons;
    const name =
      this.name ||
      this._injector.get(NgControl)?.name ||
      Math.random().toString();

    radioButtons.forEach((but) => {
      but.name = name as string;
      but.updateValue(this.value);

      if (this.disabled) {
        but.setDisabledState(this.disabled);
      }
    });

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = new Subscription();
    this.subscription.add(
      merge(...radioButtons.map((comp) => comp.valueChanged)).subscribe(
        (value) => this.updateValue(value),
      ),
    );
  }

  value?: any;

  @Input() disabled = false;
  @Output() valueChanged = new EventEmitter();

  changed = (val: any) => {};
  touched = () => {};

  constructor(
    private cdr: ChangeDetectorRef,
    private _injector: Injector,
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateValue(value: any) {
    this.value = value;
    this.updateChildValues();
    this.touched();
    this.changed(value);
    this.valueChanged.emit(value);
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.updateChildValues();
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._radioButtons?.forEach((but) => but.setDisabledState(isDisabled));
  }

  private updateChildValues() {
    this._radioButtons?.forEach((but) => but.updateValue(this.value));
  }
}
