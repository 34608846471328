import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { RadioButtonComponent } from './radio-button/radio-button.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { RadioGroupHorizontalComponent } from './radio-group-horizontal/radio-group.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [
    RadioGroupComponent,
    RadioButtonComponent,
    RadioGroupHorizontalComponent,
  ],
  exports: [
    RadioGroupComponent,
    RadioButtonComponent,
    RadioGroupHorizontalComponent,
  ],
})
export class FrontendSharedUiRadioGroupModule {}
