/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { InputErrorDirective } from '../input-error/input-error.directive';
// tslint:disable: no-host-metadata-property

@UntilDestroy()
@Component({
  selector: 'mspot-form-section-field-sm',
  templateUrl: './form-section-field-sm.component.html',
  styles: [],
  host: {
    class:
      'block space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionFieldSmComponent {
  id!: string;
  @Input() label = '';
  control!: NgControl;

  @ContentChild(InputErrorDirective) set inputError(val: InputErrorDirective) {
    if (val) {
      this.id = val.host.id;
      this.control = val.ngControl as NgControl;
      val.registerCdr(this.cdr);
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
