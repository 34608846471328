import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mspot-svg-boolean',
  templateUrl: './svg-boolean.component.html',
  styles: [],
  host: {
    class: 'flex space-x-4',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SvgBooleanComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgBooleanComponent implements ControlValueAccessor {
  @Input() value?: boolean = false;
  @Input() trueText = '';
  @Input() falseText = '';
  @Input() trueTooltip?: string;

  @Output() valueChanged = new EventEmitter();

  changed = (val: any) => {};
  touched = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  updateValue(val: boolean) {
    this.touched();
    this.value = val;
    this.changed(val);
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }
}
