import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'mspot-radio-button',
  templateUrl: './radio-button.component.html',
  styles: [],
  host: {
    class: 'flex items-center gap-2',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent {
  @Input() value?: any;

  @Input() label?: string;
  @Input() disabled = false;

  checked?: boolean;

  name = '';

  id = Math.random().toString();

  valueChanged = new Subject();

  constructor(private cdr: ChangeDetectorRef) {}

  updateValue(val: any) {
    this.checked = this.value === val;
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
