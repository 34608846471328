<input
  type="radio"
  (change)="valueChanged.next(value)"
  [disabled]="disabled"
  [checked]="checked"
  [name]="name"
  [id]="id"
/>
<label class="text-sm font-medium" [for]="id">
  <ng-content></ng-content>
</label>
