/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive, forwardRef, HostBinding, Input } from '@angular/core';

import { InputErrorDirective } from './input-error.directive';
import { InputErrorBaseDirective } from './input-error-base.directive';

@Directive({
  selector: '[mspotInputNoborderFocus]',
  host: {
    class: `
      block 
      rounded-md 
      bg-transparent
      min-w-input
      border-transparent
      focus:border-primary
      hover:border-gray-900
      rounded-none
      ring-0
      border-0
      border-b
      px-1
      `,
    autocomplete: 'off',
    type: 'text',
  },
  providers: [
    {
      provide: InputErrorDirective,
      useExisting: forwardRef(() => InputErrorNoBorderFocusDirective),
    },
  ],
})
export class InputErrorNoBorderFocusDirective extends InputErrorBaseDirective {
  @Input()
  @HostBinding('class')
  classes = 'text-sm';

  errorClasses = 'text-red-500';
  disabledClasses = 'text-gray-400';
  validClasses = 'text-gray-900';

  @Input()
  @HostBinding('class.w-full')
  fullWidth = true;
}
