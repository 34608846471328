import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { RadioGroupBaseComponent } from '../radio-group-base/radio-group.component';

@UntilDestroy()
@Component({
  selector: 'mspot-radio-group:not([horizontal])',
  templateUrl: './radio-group.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioGroupComponent extends RadioGroupBaseComponent {}
