import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormErrorObjPipe } from './pipes/form-error-obj.pipe';
import { FormStatusPipe } from './pipes/form-status.pipe';
import { FormValidPipe } from './pipes/form-valid.pipe';

const pipes = [FormValidPipe, FormErrorObjPipe, FormStatusPipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class FrontendSharedUtilFormModule {}
