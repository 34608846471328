import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FrontendSharedUiTooltipModule } from '@memberspot/frontend/shared/ui/tooltip';

@Component({
  selector: 'mspot-toggle',
  templateUrl: './toggle.component.html',
  styles: [],
  standalone: true,
  imports: [CommonModule, FrontendSharedUiTooltipModule],
  host: {
    class: 'inline-flex items-center gap-3',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input() subtitle?: string;
  @Input() value?: boolean;

  @Input() switchOrder?: boolean;

  tooltip = input('');

  @Input() set checked(val: boolean) {
    this.value = val;
    this._cdr.markForCheck();
  }

  get checked() {
    return !!this.value;
  }

  @Output() valueChanged = new EventEmitter();

  @HostBinding('class.opacity-50')
  @Input()
  disabled?: boolean | null = false;

  constructor(private _cdr: ChangeDetectorRef) {}

  @HostListener('click')
  updateValue() {
    if (this.disabled) {
      return;
    }

    this.value = !this.value;
    this.changed(this.value);
    this.touched();
    this.valueChanged.emit(this.value);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changed = (val: any) => void 0;
  touched = () => void 0;

  writeValue(obj: any): void {
    this.value = obj;
    this._cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
