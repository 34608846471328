<div>
  <label [for]="id" class="title-sm block break-words">
    {{ label }}
  </label>
  <div class="subtitle-sm break-words">
    {{ subtitle }}
  </div>
</div>
<div class="sm:col-span-2">
  <mspot-form-field-base
    [invalid]="control?.touched && control?.invalid"
    [errors]="control?.errors"
  >
    <ng-content></ng-content>
    <span suffix>
      <ng-content select="[mspotInputSuffix]"></ng-content>
    </span>
  </mspot-form-field-base>
</div>
