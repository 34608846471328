/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive, forwardRef, HostBinding, Input } from '@angular/core';

import { INPUT_PREFIX } from '../form-field-std/form-field-std.component';

@Directive({
  selector: '[mspotInputPrefix]',
  host: {
    class: 'block absolute inset-y-0 left-0 flex items-center pl-3 ',
  },
  providers: [
    {
      provide: INPUT_PREFIX,
      useExisting: forwardRef(() => InputPrefixDirective),
    },
  ],
})
export class InputPrefixDirective {
  @HostBinding('class.pointer-events-none')
  @Input()
  noPointerEvents = true;

  constructor() {}
}
