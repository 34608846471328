import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FrontendSharedUiTooltipModule } from '@memberspot/frontend/shared/ui/tooltip';

import { CheckboxComponent } from './checkbox/checkbox.component';
import { CheckboxNoLabelComponent } from './checkbox-no-label/checkbox.component';

@NgModule({
  imports: [CommonModule, FrontendSharedUiTooltipModule],
  declarations: [CheckboxComponent, CheckboxNoLabelComponent],
  exports: [CheckboxComponent, CheckboxNoLabelComponent],
})
export class FrontendSharedUiCheckboxModule {}
