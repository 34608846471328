import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FrontendSharedUiTooltipModule } from '@memberspot/frontend/shared/ui/tooltip';

import { SvgBooleanComponent } from './svg-boolean/svg-boolean.component';

@NgModule({
  imports: [CommonModule, FrontendSharedUiTooltipModule],
  declarations: [SvgBooleanComponent],
  exports: [SvgBooleanComponent],
})
export class FrontendSharedUiSvgControlsModule {}
