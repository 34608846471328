<button
  class="relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
  role="switch"
  aria-checked="false"
  type="button"
  [ngClass]="[
    value ? 'bg-primary' : 'bg-gray-200',
    disabled
      ? 'cursor-default focus-visible:ring-transparent'
      : 'focus-visible:ring-primary cursor-pointer',
    switchOrder ? 'order-1' : ''
  ]"
>
  <span class="sr-only">Use setting</span>

  <span
    class="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
    [ngClass]="value ? 'translate-x-5' : 'translate-x-0'"
  >
    <span
      class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
      aria-hidden="true"
      [ngClass]="
        value
          ? 'opacity-0 duration-100 ease-out'
          : 'opacity-100 duration-200 ease-in'
      "
    >
      <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
        <path
          d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>

    <span
      class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
      aria-hidden="true"
      [ngClass]="
        value
          ? 'opacity-100 duration-200 ease-in'
          : 'opacity-0 duration-100 ease-out'
      "
    >
      <svg class="text-primary h-3 w-3" fill="currentColor" viewBox="0 0 12 12">
        <path
          d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
        />
      </svg>
    </span>
  </span>
</button>
@if (label) {
  <span class="flex cursor-default items-center">
    <span class="whitespace-pre-line text-sm font-medium text-gray-900"
      >{{ label }}
    </span>
    @if (subtitle) {
      <span class="ml-2 text-sm text-gray-500">{{ subtitle }}</span>
    }
    @if (tooltip()) {
      <mspot-tooltip-info-icon
        [tooltip]="tooltip()"
        class="text-primary"
      ></mspot-tooltip-info-icon>
    }
    <div class="flex items-center" (click)="$event.stopPropagation()">
      <ng-content></ng-content>
    </div>
  </span>
}
