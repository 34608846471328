<button type="button" (click)="updateValue(true)">
  <div
    class="w-24 rounded border-2"
    [ngClass]="value ? 'border-primary' : 'border-transparent'"
  >
    <ng-content select="[slot='true']"></ng-content>
  </div>
  <div class="subtitle-new flex items-center justify-center">
    {{ trueText }}
    @if (trueTooltip) {
      <mspot-tooltip-info-icon
        [tooltip]="trueTooltip"
        class="-ml-1"
      ></mspot-tooltip-info-icon>
    }
  </div>
</button>

<button type="button" (click)="updateValue(false)">
  <div
    class="w-24 rounded border-2"
    [ngClass]="!value ? 'border-primary' : 'border-transparent'"
  >
    <ng-content select="[slot='false']"></ng-content>
  </div>

  <div class="subtitle-new">{{ falseText }}</div>
</button>
