import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIconsModule } from '@ng-icons/core';

import { TooltipInfoIconComponent } from './tooltip-info-icon/tooltip-info-icon.component';
import { TooltipInfoIconSmComponent } from './tooltip-info-icon-sm/tooltip-info-icon-sm.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule, NgIconsModule],
  declarations: [TooltipInfoIconComponent, TooltipInfoIconSmComponent],
  exports: [TooltipInfoIconComponent, TooltipInfoIconSmComponent],
})
export class FrontendSharedUiTooltipModule {}
