/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';

import { INPUT_SUFFIX } from '../form-field-std/form-field-std.component';

@Component({
  selector: 'button[mspotInputSuffix]',
  templateUrl: './input-suffix-btn.component.html',
  styles: [],
  host: {
    class: 'block absolute inset-y-0 right-0 flex items-center pr-3',
    type: 'button',
  },
  providers: [
    {
      provide: INPUT_SUFFIX,
      useExisting: forwardRef(() => InputSuffixBtnComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSuffixBtnComponent {
  @Input('mspotInputSuffix') icon: any;

  constructor() {}
}
