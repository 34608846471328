/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  Directive,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { InputErrorDirective } from './input-error.directive';

@Directive({
  selector: '[mspotInput][width]',
  host: {
    autocomplete: 'off',
  },
  providers: [
    {
      provide: InputErrorDirective,
      useExisting: forwardRef(() => InputNoMinDirective),
    },
  ],
})
export class InputNoMinDirective extends InputErrorDirective {
  @HostBinding('style.width.px')
  @Input('width')
  width = 200;

  constructor(elRef: ElementRef, @Optional() @Self() ngControl?: NgControl) {
    super(elRef, ngControl);
  }
}
