/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mspot-form-sections-wrapper',
  template: ` <ng-content></ng-content> `,
  host: {
    class:
      'block py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionsWrapperComponent {
  constructor() {}
}
